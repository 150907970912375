<template lang="pug">
layout-sider(
  :width="550"
  :collapsible="false"
  className="sider-main")
  div(
    :style="{margin: '0 auto', display: 'flex', width: '357px', height:'100vh', flexDirection:'column'}")
    logo(
    mode="login"
    :customStyle="{margin: '10% 0 25% 0'}")
    div(:style="{marginBottom: '10%'}")
      AppH2(
        content="Bienvenido a "
        className="regular-container")
      AppH2(
        content="The Digital ShowRooms"
        className="regular-color")
      AppH2(
        content=","
        className="regular-container")
      br
      AppH2(
        content="indíque su correo electrónico para comenzar"
        className="regular-container")
    a-form(
      :form="form"
      @submit.prevent="login")
      a-form-item
        app-text(
          content="Correo electrónico:"
          className="regular-spaced-light inside-container")
        a-input(
          v-decorator="['email',{ rules: [{required: true, message: 'El correo electrónico es necesario'}, {type: 'email', message: 'Ingresa un correo valido'}] }]")
        app-text(
          v-show="errorAuth"
          :customStyle="{marginBottom: '12%'}"
          className="regular-error nano"
          :content="errorAuth")
      app-button(
        :loading="isLoadingAuth"
        type="primary"
        label="Acceder"
        className="block"
        htmlType="submit"
        :customStyle="{marginBottom: '10%'}")
    div(:style="{display:'flex', alignItems:'center', justifyContent:'space-between'}")
      app-text(
        className="small text-primary link"
        :customStyle="{textAlign: 'left', paddingRight:'10px'}"
        content="Iniciar sesión como comercial"
        @click="goToLoginComercial")
      app-button(
        v-show="showAddToCalendar"
        type="primary"
        icon="calendar"
        label="Añadir al calendario"
        :handler="showModal")
    a-modal(
        v-model="visible",
        title="Agregar Al Calendario")
        div(:style="{padding:'1rem'}")
          add-to-calendar(
            title="Agendar ShowRoom"
            :start="meetingStart"
            :end="meetingEnd"
            :location="`${origin}/${token? '?access_token=' + token : '?email=' + email}`"
            detail="Presentación The Digital ShowRooms"
            inline-template)
            div
              google-calendar(id="google-calendar")
                div(:style="{  textAlign: 'left',fontSize: '1.2rem'}") Agregar a Google calendar
              microsoft-calendar(id="microsoft-calendar")
                div(:style="{  textAlign: 'left',fontSize: '1.2rem'}") Agregar a Microsoft live calendar
              office365-calendar(id="office365-calendar")
                div(:style="{  textAlign: 'left',fontSize: '1.2rem'}") Agregar a Office365 outlook calendar
</template>
<script>
//Import atoms components
import AppH2 from "@/components/atoms/AppH2.vue";
import AppText from "@/components/atoms/AppText.vue";
import AppButton from "@/components/atoms/AppButton.vue";
import AppImage from "@/components/atoms/AppImage.vue";

//import molecules components
import Logo from "@/components/molecules/Logo.vue";
//Import organisms components
import LayoutSider from "@/components/organisms/LayoutSider.vue";

//Import Login functions
import {
  VERIFY_ATTENDANT_EMAIL,
  VERIFY_ATTENDANT_TOKEN
} from "../../../store/actions.type";

import { SET_ERROR_AUTH } from "../../../store/mutations.type";

import { mapGetters } from "vuex";

export default {
  name: "SiderBarLeft",
  components: {
    LayoutSider,
    AppH2,
    AppText,
    AppButton,
    AppImage,
    Logo
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: "coordinated" }),
      meetingStart: "",
      meetingEnd: "",
      showAddToCalendar: false,
      visible: false,
      origin: window.origin,
      token: undefined,
      email: undefined
    };
  },
  computed: {
    ...mapGetters(["isLoadingAuth", "errorAuth", "getThemeName"])
  },
  created: function() {
    if (this.$session.exists()) {
      let data = this.$session.getAll();
      if (data.ShowRoomClient !== null && data.ShowRoomClient !== undefined)
        this.$router.push({
          name: "ClientLobby"
        });
      else if (
        data.showRoomComercialId !== null &&
        data.showRoomComercialId !== undefined
      )
        this.$router.push({
          name: "Dashboard/Panel"
        });
    } else if (
      this.$route.query.access_token !== undefined ||
      this.$route.query.email !== undefined
    ) {
      this.token = this.$route.query.access_token;

      if (this.$route.query.access_token) {
        this.$store
          .dispatch(VERIFY_ATTENDANT_TOKEN, this.$route.query.access_token)
          .then(data => {
            const { client, salesroom } = data;

            if (client && salesroom) {
              this.showAddToCalendar = false;
              this.$session.start();
              this.$session.set("user", client);
              this.$session.set("ShowRoomClient", salesroom);
              this.$router.push({
                name: "ClientLobby"
              });
            } else {
              this.$router.push({
                name: "LoginClient"
              });

              if (
                data.response.data.access_token.message &&
                data.response.data.access_token.message.startsWith(
                  "El Showroom esta programada para:"
                )
              ) {
                let date = new Date(data.response.data.access_token.date);

                this.meetingStart = date;

                this.meetingEnd = new Date(date.getTime() + 30 * 60000);

                this.showAddToCalendar = true;

                return;
              }
            }
          });
      } else if (this.$route.query.email) {
        this.$store
          .dispatch(VERIFY_ATTENDANT_EMAIL, this.$route.query.email)
          .then(data => {
            if (this.errorAuth) {
              if (
                this.errorAuth.startsWith("El Showroom esta programada para:")
              ) {
                let date = new Date(data.response.data.date[0]);

                this.meetingStart = date;

                this.meetingEnd = new Date(date.getTime() + 30 * 60000);

                this.showAddToCalendar = true;
              }
            } else {
              const { client, salesroom, entry_time, exit_time } = data;

              if (exit_time) {
                this.showAddToCalendar = false;
                this.$store.commit(SET_ERROR_AUTH, { cameOut: true });
              } else {
                this.showAddToCalendar = false;
                this.$session.start();
                this.$session.set("user", client);
                this.$session.set("ShowRoomClient", salesroom);
                this.$router.push({
                  name: "ClientLobby",
                  params: { user_entry_time: entry_time }
                });
              }
            }
          });
      }
    }
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    goToLoginComercial() {
      this.$router.push({
        name: "LoginComercial"
      });
    },
    login() {
      this.form.validateFields((error, values) => {
        if (!error) {
          const { email } = values;
          this.email = email;
          this.$store.dispatch(VERIFY_ATTENDANT_EMAIL, email).then(data => {
            if (this.errorAuth) {
              if (
                this.errorAuth.startsWith("El Showroom esta programada para:")
              ) {
                let date = new Date(data.response.data.date[0]);

                this.meetingStart = date;

                this.meetingEnd = new Date(date.getTime() + 30 * 60000);

                this.showAddToCalendar = true;
              }
            } else {
              const { client, salesroom, entry_time, exit_time } = data;

              if (exit_time) {
                this.showAddToCalendar = false;
                this.$store.commit(SET_ERROR_AUTH, { cameOut: true });
              } else {
                this.showAddToCalendar = false;
                this.$session.start();
                this.$session.set("user", client);
                this.$session.set("ShowRoomClient", salesroom);
                this.$router.push({
                  name: "ClientLobby",
                  params: { user_entry_time: entry_time }
                });
              }
            }
          });
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
//Import globals styles
@import "../../../../less/main.less";

.sider-text-container {
  width: 357px;
}

.ant-input {
  background-color: var(--container-color) !important;
  border: 0 !important;
  border-bottom: 1px solid var(--primary-color) !important;
  color: var(--container-text-color);
  border-radius: 0;
}
// .btn-fluid {
//   height: 45px;
//   width: 100%;
//   font-size: 18px;
//   letter-spacing: 1px;
// }
</style>
