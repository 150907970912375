<template lang="pug">
    app-image(
        v-else
        :url="rederedBanner"
        :customStyle="{width:'100%',height:'100vh', objectFit:'cover'}")
</template>

<script>
//atoms components
import AppImage from "@/components/atoms/AppImage.vue";
//banner images imports
import bannerSgp from "@/assets/banners/banner-sgp.jpg";
import bannerAlaplana from "@/assets/banners/banner-alaplana.jpg";
import bannerVitacer from "@/assets/banners/banner-vitacer.jpg";
import bannerKeratile from "@/assets/banners/banner-keratile.jpg";
import bannerStn from "@/assets/banners/banner-stn.jpg";

//vuex
import { mapGetters } from "vuex";
export default {
  name: "BannerImage",
  components: {
    AppImage
  },
  data() {
    return {
      bannerSgp,
      bannerAlaplana,
      bannerVitacer,
      bannerKeratile,
      bannerStn
    };
  },
  computed: {
    ...mapGetters(["getThemeName"]),
    rederedBanner() {
      switch (this.getThemeName) {
        case "alaplana":
          return this.bannerAlaplana;
        case "stn":
          return this.bannerStn;
        case "keratile":
          return this.bannerKeratile;
        case "vitacer":
          return this.bannerVitacer;
        default:
          return this.bannerVitacer;
      }
    }
  }
};
</script>

<style></style>
