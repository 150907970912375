<template lang="pug">
a-modal(
  :visible="visible")
  a-result(
    title="El ShowRoom ha finalizado, gracias por asistir.")
    template(#icon)
      a-icon(type="smile")
  template(slot="footer")
    app-button(
      :handler="closeModal"
      type="primary"
      label="Salir")
</template>
<script>
//import atoms components
import AppButton from "@/components/atoms/AppButton.vue";

export default {
  name: "EndShowRoomModal",
  components: {
    AppButton
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    }
  }
};
</script>
