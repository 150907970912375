<template lang="pug">
Layout
  sider-bar-left
  banner-image
  end-show-room-modal(
    v-on:close-modal="closeModal"
    :visible="visible")
</template>
<script>
//Import atoms components
import AppImage from "@/components/atoms/AppImage.vue";
import AppButton from "@/components/atoms/AppButton.vue";

//Import molecules
import BannerImage from "@/components/molecules/Banner.vue";
//Import organisms components
import Layout from "@/components/organisms/Layout.vue";

//Import customComponents from this component
import SiderBarLeft from "@/components/customComponents/LoginClient/SiderBarLeft.vue";
import EndShowRoomModal from "@/components/customComponents/LoginClient/EndShowRoomModal.vue";

export default {
  name: "LoginClient",
  components: {
    Layout,
    SiderBarLeft,
    AppImage,
    EndShowRoomModal,
    AppButton,
    BannerImage
  },
  data() {
    return {
      visible: false
    };
  },
  beforeMount() {
    if (this.$route.params.finishedShowRoom) {
      this.visible = true;
    }
  },
  methods: {
    closeModal() {
      this.visible = false;
    }
  }
};
</script>
